.single-post-container {
    border-bottom: 1px solid rgb(172, 172, 172);
    background-color: rgba(247, 217, 172, 0.151);
    max-height: 230px;
    padding: 20px;
    overflow: hidden;
    color: #000;
    border-left: 3px solid rgb(159, 216, 241);
}
.single-post-container:hover {
    cursor: pointer;
}

.single-post-container img {
    max-width: 150px;
    max-height: 150px;
    margin: auto;
    margin-top: 10px;
    background-color: #fff;
}

.single-post-body {
}
.single-post-action {
    max-width: 50px;
    color:  rgb(221, 244, 165);
    border: 1px solid #ccc;
    margin: 10px;
    vertical-align: middle;
    padding: 3px;
}

#latestPosts-container {
    max-height: 40vh;
    overflow-y: auto;
    margin-top: 0px;
    
}

.profileEntry {
    text-align: left;
}

.profileEntry .profilePic {
    background-repeat: no-repeat;
    width: 50px;
    height: 50px;
    background-size: cover;
    border-radius: 180px;
    border: 1px solid #ccc;
    padding: 30px;
}

.profileEntry .username {
    vertical-align: center;
    padding: 15px;
    color: #000;
}
.profileEntry .coinPrice {
    vertical-align: center;
    padding: 15px;
    padding-left: 0px;
    font-style: italic;
    color: #000;
}

.postLink {
    display: inline;
}