#buydesoinu-container {
}

#buydesoinu-container img {
 
}

.latestPosts-container {
    background-color: #E1DFCF;
    border-radius: 30px;
    padding: 30px;
    padding-right: 10px;
}
.buy-container {
    background-color: #FF8A00;
    border-radius: 50px;
    padding: 50px;
}

.buy-container h1 {
    font-size: 24px;
}
.buy-container input {
    border-radius: 50px;
    height: 30px;
    padding: 20px;
    text-align: center;
    font-weight: bolder;
}

.buy-container .buyBtn {
    width: 80%;
    background-color: #30C3EE;
    padding: 10px;
    color: #FEFEFE;
    border-radius: 50px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 3px;
}