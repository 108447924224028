@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fff;
  color: #000000;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  text-decoration: none;
  color: cyan;
}

a:visited {
  color: magenta;
}

.main-content {
  text-align: center;
  padding: 3rem;
  padding-top: 4rem;
}

.main-nav {
  display: flex;
  align-items: center;
  padding: 0.5rem 10rem;
  border-bottom: 1px solid #444;
  background-color: #DA5E02;

}

@media screen and (max-width: 1000px) {
  .main-nav {
    padding: 0.5rem 1rem;
  }
}

.main-nav__user-actions {
  margin-left: auto;
}

.main-nav__username {
  margin-right: 1rem;
}

nav a:visited {
  color: #eee;
}

nav a, nav button {
  cursor: pointer;
  padding: 0.5rem 1rem;
  text-decoration: none;
  color: #eee;
  border: none;
  background-color: transparent;
  font-size: medium;
}

.list--unstyled {
  list-style: none;
  padding: 0;
}

.switch-account__button {
  border: none;
  background-color: transparent;
  cursor: pointer;
  color: cyan;
  font-size: large;
  padding: 0.5rem 1rem;
}

.switch-account__list {
  width: 10rem;
  margin: 0 auto;
  text-align: left;
}


.subHeader {
  color: #B0AA92;
}

.header1 {
  color: #461301;
  font-size: 44px;
}


/*       ScrollBar         */

*::-webkit-scrollbar {
  width: 10px;
  margin-right: 10px;
}

*::-webkit-scrollbar-track {
  border-radius: 8px;
  background-color: #e7e7e7;
  border: 1px solid #cacaca;
  margin-right: 10px;
}

*::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background-color: #7CD3EB;
  margin-right: 10px;
}